import { useMemo } from 'react'
import { useSelector } from 'react-redux'

type useLogoProps = {
  width?: number
  height?: number
  src?: string
  alt?: string
}

export const useLogo = (props: useLogoProps) => {
  const storeConfig = useSelector((state: any) => state.app.storeConfig)

  const logo = useMemo(() => {
    return storeConfig && storeConfig.logo_src
      ? {
          src: `${storeConfig.secure_base_media_url}logo/${storeConfig.logo_src}`,
          width: props.width || storeConfig.logo_width || 65,
          height: props.height || storeConfig.logo_height || 65,
          alt: storeConfig.logo_alt
        }
      : {}
  }, [props.height, props.width, storeConfig])

  return {
    logo
  }
}
